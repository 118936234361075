<!-- Tabs.vue -->
<template>
  <div class="tabs">
    <div class="tab-headers">
      <div
        v-for="(tab, index) in data"
        :key="index"
        @click="selectTab(index)"
        :class="['tab-header', { 'active': selectedTab === index }]"
      >
        {{ tab.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedTab: 0
    };
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
      this.$emit('tab-selected', index,this.data[index]);
    }
  }
};
</script>

<style scoped>
.tabs {
  width: 100%;
}
.tab-headers {
  display: flex;
  overflow-x: auto;
  width: max-content;
}
.tab-header {
  padding: 10px;
  margin-right: 5px;
  font-family: PingFangSC, PingFang SC;

  font-weight: 600;
  font-size: 18px;
  background: linear-gradient(0deg, #66FFFF 0%, #C3F8B3 100%);
  -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
  -webkit-text-fill-color: transparent; /*给文字设置成透明*/
}
.tab-header.active {
  font-size: 20px;
  color: #C3F8B3 !important;
  border-bottom: 2px solid #C3F8B3; /* 设置下方横线的颜色和透明度 */
}
</style>
